.clip-path-polygon {
    clip-path: polygon(0 0, 100% 0, 85% 100%, 0 100%);
}

.overlay-slide-in {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 5;
    animation: slideOut 2s ease-in-out forwards;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay-text {
    color: white;
    text-align: center;
    animation: fadeOut 1s ease-in-out forwards;
    animation-delay: 0.5s;
}

.overlay-text h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.overlay-text p {
    font-size: 1.5rem;
}

.content-slide-in {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.content-slide-in.slide-in {
    opacity: 1;
    transform: translateX(0);
}

@keyframes slideOut {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}