/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Yuji+Syuku&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Old+Mincho&display=swap");

:root {
    font-family: "Noto Sans JP", sans-serif; 
   
  }

@Layer components {
    .bannerIcon{
        @apply w-16 h-16 bg-black bg-opacity-25 text-gray-200 text-4xl inline-flex items-center justify-center
        rounded-md shadow-2xl hover:bg-opacity-40 hover:-translate-y-1 transition-all hover:text-designColor duration-300
        cursor-pointer border-[1px] border-slate-200 
    }
}
